
.efficiency-label {
  &.negative .background-rect {
    fill: #e93e49;
  }
  &.neutral .background-rect {
    fill: #ffd08c;
  }
  &.positive .background-rect {
    fill: #00d280;
  }
  &.freq-none {
    opacity: 0.4;
    .background-rect,
    .background-rect-filler {
      opacity: 0;
    }
  }
  &.freq-low .background-rect {
    opacity: 0.2;
  }
  &.freq-mid .background-rect {
    opacity: 0.6;
  }
  &.freq-high .background-rect {
    opacity: 1;
  }
}

.dashboard-table-row {
  &.disabled {
    .player-label {
      fill: #ccc !important;
    }
    .efficiency-label {
      .background-rect {
        fill: #ccc !important;
      }
      opacity: 0.7 !important;
    }
  }
}

.bar-chart-item {
  &.disabled {
    fill: #ccc !important;
  }
}

.dashboard-wrapper {
  svg {
    text {
      font-family: 'Avenir-Heavy', 'Avenir', 'Lato', sans-serif;
      font-weight: 600;
      fill: #222;
    }

    &.light-color {
      text[fill="#FFFFFF"] {
        fill: #ffffff;
      }
    }

    text[fill="#FFFFFF"] tspan {
      fill: #ffffff;
    }
  }
  text-align: center;
}



//

.matrix-table{
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  overflow: auto;
  font-size:10px;
  height: 80vh;

  table{
    font-size: 1em;
    border-collapse: collapse;
  }
  th{
    writing-mode: sideways-lr;
    position: sticky;
    top: 0px;
  }

  td.empty{
    background-color: #ccc;
  }
  td, th{
    border: solid 1px white;
  }
  td:not(.empty){


  }
  //td.high{
  //  color: #0fcf84;
  //}

  /*This will work on every browser but Chrome Browser*/
thead {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
    background-color: #3e3e3e;
    color: #fff;
  }

  /*This will work on every browser*/
  thead th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
    background-color: #3e3e3e;
    color: #fff;
  }
  th:first-child,
  td:first-child{
    position: sticky;
    left: 0;
    background-color: #3e3e3e;
    color: #fff;
    z-index: 1;
  }
  th:first-child{
    z-index: 3;
    border: solid 1px #3e3e3e;
  }

  tr:nth-child(even){
    td.empty{
      background-color: #9a9a9a;
    }
    td:not(:first-child):not(.empty){
      background-color: #eeeeee;
      &:hover{
        background-color: #d7f3ea;
      }
    }
  }
  td:not(:first-child):not(.empty){
    cursor: pointer;
    &:hover{
      background-color: #d7f3ea;
    }
  }



}

.matrix-player{
  aspect-ratio: 16/9;
  #video-player-wrapper{
    height:100%;
  }
  margin-bottom: 2em;
}